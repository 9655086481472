import React, {useEffect, useState} from "react"
import {useNavigate} from "react-router-dom"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import AddIcon from "@mui/icons-material/Add"
import DeleteIcon from "@mui/icons-material/Delete"
import EditIcon from "@mui/icons-material/Edit"
import AttachFileIcon from "@mui/icons-material/AttachFile"
import DataRow from "@/components/ui/DataRow"
import Page from "@/layouts/Page"
import {Button} from "@mui/material"
import {Box, TextField} from "@mui/material"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Select, {SelectChangeEvent} from "@mui/material/Select"
import Chip from "@mui/material/Chip"
import OutlinedInput from "@mui/material/OutlinedInput"
import Alert from "@mui/material/Alert"
import UploadImagesForm from "@/components/ui/UploadImagesForm"
import {postInmueble, putInmueble} from "@/api/inmueble"
import {checkNameTaken} from "@/api/utils"
import {getAllConds} from "@/api/condominio"
import {getAssociates} from "@/api/asociados"
import {ReactSortable} from "react-sortablejs"
import {nanoid} from "nanoid"
import {
   environments as envs,
   allEnvironments as allEnvs,
   furnishTypes,
   zones,
   Status,
} from "@/constants"
import {isMobile} from "@/utils"
import ConfirmationDialog from "@/components/ui/ConfirmationDialog"
import BooleanToggle from "@/components/properties/BooleanToggle"
import {PropertyType} from "@/types/Property"

interface Condominio {
   value: string
   label: string
}

interface Partners {
   value: string
   label: string
}

interface SortableSectionsType {
   name: string
   id: string
}

function AddInmueble() {
   const navigate = useNavigate()
   const [error, setError] = useState<boolean | string>(false)
   const [condominios, setCondominio] = useState<Condominio[]>()
   const [name, setName] = useState<null | string>(null)
   const [status, setStatus] = useState<Status>(Status.VERIFIED)
   const [desc, setDesc] = useState<null | string>(null)
   const [desc2, setDesc2] = useState<null | string>(null)
   const [urlCAD, setUrlCad] = useState<null | string>(null)
   const [condo, setCondo] = useState<null | string>(null)
   const [type, setType] = useState<PropertyType>(PropertyType.HOUSE)
   const [capacity, setCapacity] = useState<null | number>(null)
   const [mts, setMts] = useState<null | number>(null)
   const [mtsContruccion, setMtsContruccion] = useState<null | number>(null)
   const [floor, setFloor] = useState<null | number>(null)
   const [levels, setLevels] = useState<null | number>(null)
   const [rooms, setRooms] = useState<null | number>(null)
   const [bathrooms, setBathrooms] = useState<null | number>(null)
   const [parkingLots, setParkingLots] = useState<null | number>(null)
   const [mensual_anual, setMensualAnual] = useState<null | number>(null)
   const [mensual_regular, setMensualRegular] = useState<null | number>(null)
   const [noche_alta, setNocheAlta] = useState<null | number>(null)
   const [noche_media, setNocheMedia] = useState<null | number>(null)
   const [noche_regular, setNocheRegular] = useState<null | number>(null)
   const [costoVenta, setCostoVenta] = useState<null | number>(null)
   const [seaview, setSeaview] = useState<string>("")
   const [furnished, setFurnished] = useState<string>("")
   const [furnishType, setFurnishType] = useState<string>("")
   const [mapsUrl, setMapsUrl] = useState<string>("")
   const [mapsShareUrl, setMapsShareUrl] = useState<string>("")
   const [maintenance, setMaintenace] = useState<null | number>(null)
   const [zone, setZone] = useState<null | string>("")
   const [environments, setEnvironments] = useState<string[]>([])
   const [outingToTheBeach, setOutingToTheBeach] = useState<string>("")
   const [houseAntiquity, setHouseAntiquity] = useState<null | string>(null)
   const [available, setAvailable] = useState<string>("true")
   const [suspended, setSuspended] = useState<string>("false")
   const [notes, setNotes] = useState<null | string>(null)
   const [privateNotes, setPrivateNotes] = useState<null | string>(null)
   const [partners, setPartners] = useState<string[]>([])
   const [partnersFetched, setPartnersFetched] = useState<Partners[]>([])
   const [address, setAddress] = useState<null | string>(null)
   const [petFriendly, setPetFriendly] = useState<string>("")
   const [file, setFile] = useState<File | null>(null)
   const [videos, setVideos] = useState<string>("")
   const [videosList, setVideosList] = useState<string[]>([])
   const [showImageForm, setShowImageForm] = useState<boolean>(false)
   const [categoria, setCategoria] = useState<string>("")
   const [categories, setCategories] = useState<Array<SortableSectionsType>>([])
   const [currImage, setCurrImage] = useState<string>("")
   const [images, setImages] = useState<any>()
   const [loading, setLoading] = useState<boolean>(false)
   const [confirmationOpen, setConfirmationOpen] = useState<boolean>(false)
   const [categoryToDelete, setCategoryToDelete] = useState(0)
   const [saleComission, setSaleComission] = useState<number | null>(null)

   /**
    * Boolean to check if the property is a land.
    */
   const isLand = type === PropertyType.LAND

   // in order to update the enviroment state, which is an string array
   const handleChangeEnvironments = (event: SelectChangeEvent<typeof environments>) => {
      const {
         target: {value},
      } = event
      setEnvironments(
         // On autofill we get a stringified value.
         typeof value === "string" ? value.split(",") : value,
      )
   }

   const handleChangePartners = (event: SelectChangeEvent<typeof partners>) => {
      const {
         target: {value},
      } = event
      setPartners(
         // On autofill we get a stringified value.
         typeof value === "string" ? value.split(",") : value,
      )
   }

   const imageHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
      setFile(null)
      setError(false)
      if (!e.target.files) {
         setError("Selecciona un imagen")
         return
      }
      if (!e.target.files[0].type.includes("image")) {
         setError("Solo se aceptan imagenes")
         return
      }
      if (
         e.target.files[0].name.endsWith("jpg") ||
         e.target.files[0].name.endsWith("png") ||
         e.target.files[0].name.endsWith("jpeg") ||
         e.target.files[0].name.endsWith("JPEG") ||
         e.target.files[0].name.endsWith("JPG") ||
         e.target.files[0].name.endsWith("PNG")
      ) {
         setFile(e.target.files[0])
         setError(false)
      } else {
         setError("Solo se aceptan imagenes jpeg o png")
         return
      }
   }

   // in the useEffect we fetch all conds to asign them to a select field later on
   useEffect(() => {
      const fetchCondominios = async () => {
         const res = await getAllConds()
         const condOptions: Condominio[] = []
         res.data.data.forEach((cond: any) => {
            condOptions.push({value: cond.id, label: cond.name})
         })
         setCondominio(condOptions)
      }
      const fetchPartners = async () => {
         const res = await getAssociates()
         const associates: Partners[] = []
         res.forEach((ass: any) => {
            associates.push({value: ass.id, label: ass.name})
         })
         setPartnersFetched(associates)
      }
      fetchCondominios()
      fetchPartners()
   }, [])

   // everytime a user adds a video, we add it to the videosList state
   const handleVideoChange = () => {
      setError(false)
      // if no video was added then an error is set
      if (videos === "") {
         setError("Ingresa el URL del video")
         return
      }
      // if the video list already exists we just add the new video to it
      if (videosList) {
         setVideosList((prevVideosList) => [...prevVideosList, videos.trim()])
      }
      // if videosList is empty, we add the video
      if (!videosList) {
         setVideosList([videos.trim()])
      }
      setVideos("")
   }

   // everytime a user adds a category, we add it to the categories state
   // also it is added to the images object that has this format:
   // images ={
   //   "categoria1": ["url1", "url2"],
   //   "categoria2": ["url3", "url4"]
   // }
   const handleCategoriasChange = () => {
      setError(false)
      // if no category was added then an error is set
      if (categoria === "") {
         setError("Ingresa una categoria")
         return
      }
      // if the category list already exists we just add the new category to it
      // and also to the images object, which will be empty until
      // it is change in the backend
      if (categories) {
         setCategories((prevCategories) => [
            ...prevCategories,
            {
               id: nanoid(),
               name: categoria.trim(),
            },
         ])
         setImages((prevImages: any) => {
            if (!prevImages) {
               return {
                  [categoria.trim()]: [],
               }
            }
            const keys = Object.keys(prevImages)
            keys.push(categoria.trim())
            const newImages: any = {}
            for (let i = 0; i < keys.length; i++) {
               newImages[keys[i]] = images[keys[i]] ? images[keys[i]] : []
            }
            return newImages
         })
      }
      setCategoria("")
   }

   // everytime the user deletes a video, we remove it from the videosList state
   const handleVideoDelete = (index: number) => {
      const videosUpdated = videosList.filter((video, i) => i !== index)
      setVideosList(videosUpdated)
   }

   // everytime the user deletes a category, we remove it from the categories state
   const handleCategoriaDelete = (index: number) => {
      setImages((prevImages: any) => {
         delete prevImages[categories[index].name]
         return prevImages
      })
      const categoriesUpdated = categories.filter((video, i) => i !== index)
      setCategories(categoriesUpdated)
   }

   // when the user decides to edit a category and add images this function
   // is called, it sets the currImage state to the category that is being edited
   // and also sets the showImageForm state to true, which will show the image form
   const handleImageEdit = (index: number) => {
      setCurrImage(categories[index].name)
      setShowImageForm(true)
   }

   const closeImageForm = () => {
      setShowImageForm(false)
   }

   // when the user clicks the upload images button in the image form
   // this function is called, it adds the images to the images state
   // in its respective category and also sets the showImageForm state to false
   const addImages = (images: any, imageCategory: string) => {
      setImages((prevImages: any) => {
         prevImages[imageCategory] = [...images]
         return prevImages
      })
      setShowImageForm(false)
   }

   // Function to handle the deletion of a category
   const handleTypeChange = async (e: SelectChangeEvent<string>) => {
      setType(e.target.value as PropertyType)
      // Reset values if land is chosen
      if (e.target.value === PropertyType.LAND) {
         setStatus(Status.VERIFIED)
         setRooms(null)
         setBathrooms(null)
         setParkingLots(null)
         setLevels(null)
         setFloor(null)
         setMtsContruccion(null)
         setFurnished("")
         setFurnishType("")
         setEnvironments([])
         setNocheRegular(null)
         setNocheAlta(null)
         setNocheMedia(null)
         setCapacity(null)
         setPetFriendly("")
      }
      // Reset environments if the type is changed
      const newEnvs: string[] = []
      for (let i = 0; i < envs.length; i++) {
         environments.forEach((env) => {
            if (env === envs[i]) {
               newEnvs.push(env)
            }
         })
      }
      setEnvironments(newEnvs)
   }

   const handleSubmit = async () => {
      setError(false)
      setLoading(true)
      const isHouse = type === PropertyType.HOUSE
      const isApartment = type === PropertyType.APARTMENT
      // Check for every required field to be filled
      if (!name || name === "") {
         setLoading(false)
         setError("Elija un nombre")
         return
      }
      const nameTaken = await checkNameTaken(name, "inmuebles")
      if (nameTaken) {
         setLoading(false)
         setError(
            "El nombre de este inmueble ya está tomado. Favor de especificar otro nombre o contactar al administrador para notificar de un problema.",
         )
         return
      }
      if (isApartment && (!condo || condo === "")) {
         setLoading(false)
         setError("Elija un condominio")
         return
      }
      if (!images) {
         setLoading(false)
         setError("Agregue una o mas categorias de imagenes")
         return
      }
      if (!seaview || seaview === "") {
         setLoading(false)
         setError("Elija si tiene vista al mar")
         return
      }
      if (!available || available === "") {
         setLoading(false)
         setError("Elija si esta disponible o no el inmueble")
         return
      }
      if (!suspended || suspended === "") {
         setLoading(false)
         setError("Elija si esta suspendido o no el inmueble")
         return
      }
      if (!file) {
         setLoading(false)
         setError("Agrega la imagen principal")
         return
      }
      if (isHouse && mapsShareUrl === "") {
         setLoading(false)
         setError("Como es una casa, el URL de Google Maps para compartir es obligatorio")
         return
      }
      if (!condo && zone === "") {
         setLoading(false)
         setError("Elija una zona porque no hay condominio asignado")
         return
      }

      const keys = Object.keys(images)
      let specificImageError = false
      for (let i = 0; i < keys.length; i++) {
         if (images[keys[i]].length <= 0) {
            setError(`Agregue una o mas imagenes a la categoria ${keys[i]}`)
            setLoading(false)
            specificImageError = true
            return
         }
      }
      if (specificImageError) {
         setLoading(false)
         return
      }
      // create the images object with the categorias fields
      // this fields are empty arrays at the moment
      const categoriesVacias: any = {}
      for (let i = 0; i < categories.length; i++) {
         categoriesVacias[categories[i].name] = []
      }
      // make the seaview property a boolean
      const seaviewBool = seaview === "true" ? true : false
      // make the furnished property a boolean
      const furnishedBool = furnished === "true" ? true : false
      // making outingToTheBeach a boolean
      const outingToTheBeachBool = outingToTheBeach === "true" ? true : false
      // populate environment arrays
      const envsUp: string[] = []
      const houseEnvsUp: string[] = []
      if (isHouse) {
         for (let i = 0; i < environments.length; i++) {
            envs.includes(environments[i])
               ? envsUp.push(environments[i])
               : houseEnvsUp.push(environments[i])
         }
      }
      // create the inmueble object with all the data
      // a check is done, so that if the user did not add a field
      // null is the default value
      const inmueble: any = {
         name,
         status,
         desc: desc === "" ? null : desc?.trim(),
         desc2: desc2 === "" ? null : desc2?.trim(),
         urlCAD: urlCAD === "" ? null : urlCAD,
         condo: condo,
         type,
         capacity: capacity === 0 ? null : capacity,
         mts: mts === 0 ? null : mts,
         mtsConstruction: mtsContruccion === 0 ? null : mtsContruccion,
         floor: floor === 0 || isHouse ? null : floor,
         levels: levels === 0 ? null : levels,
         rooms: rooms === 0 ? null : rooms,
         bathrooms: bathrooms === 0 ? null : bathrooms,
         parkingLots: parkingLots === 0 ? null : parkingLots,
         costos: {
            mensual_anual: mensual_anual === 0 ? null : mensual_anual,
            mensual_regular: mensual_regular === 0 ? null : mensual_regular,
            noche_alta: noche_alta === 0 ? null : noche_alta,
            noche_media: noche_media === 0 ? null : noche_media,
            noche_regular: noche_regular === 0 ? null : noche_regular,
         },
         costoVenta: costoVenta ? costoVenta : null,
         videos: videosList.length <= 0 ? null : videosList,
         images: categoriesVacias,
         seaview: seaviewBool,
         furnished: isLand ? null : furnishedBool,
         pricePerMeter:
            mts === 0 || costoVenta === 0 || !mtsContruccion || !costoVenta
               ? null
               : costoVenta / mtsContruccion,
         maintenance: maintenance === 0 ? null : maintenance,
         environments: isApartment
            ? environments.length <= 0
               ? null
               : environments
            : envsUp.length <= 0
            ? null
            : envsUp,
         outing_to_the_beach: !isHouse ? null : outingToTheBeachBool,
         house_environments: isApartment ? null : houseEnvsUp.length <= 0 ? null : houseEnvsUp,
         house_antiquity: houseAntiquity === "" || isApartment ? null : houseAntiquity,
         mainImage: null,
         orderedSections: categories.map((category) => category.name),
         available: available === "true" ? true : false,
         suspended: suspended === "true" ? true : false,
         notes: notes === "" ? null : notes,
         privateNotes: privateNotes === "" ? null : privateNotes,
         partners: partners.length <= 0 ? null : partners,
         address: address === "" ? null : address,
         furnishType: furnishType === "" ? null : furnishType,
         mapsUrl: mapsUrl === "" ? null : mapsUrl,
         mapsShareUrl: mapsShareUrl === "" ? null : mapsShareUrl,
         zone: zone === "" ? null : zone,
         // Set boolean scheme values depending on price presence.
         // For rent boolean is set after costos has been set
         forSale: costoVenta ? true : false,
         petFriendly: petFriendly === "" ? null : petFriendly === "true" ? true : false,
         saleComission: saleComission ? saleComission : null,
      }
      const inmuebleFields = Object.keys(inmueble)
      for (const key in inmuebleFields) {
         if (
            (inmueble[inmuebleFields[key]] === null ||
               inmueble[inmuebleFields[key]] === undefined) &&
            inmuebleFields[key] !== "mainImage"
         ) {
            delete inmueble[inmuebleFields[key]]
         }
      }
      const costosFields = Object.keys(inmueble.costos)
      for (const key in costosFields) {
         if (
            inmueble.costos[costosFields[key]] === null ||
            inmueble.costos[costosFields[key]] === undefined
         ) {
            delete inmueble.costos[costosFields[key]]
         }
      }

      const newCostos = Object.keys(inmueble.costos)
      if (newCostos.length <= 0) {
         delete inmueble.costos
      }

      // Set forRent boolean scheme depending on costos presence
      if (inmueble.costos) {
         inmueble.forRent = true
      } else {
         inmueble.forRent = false
      }

      // try catch block where we connect with the backend
      try {
         // we make the post request to the backend
         // this request will create the inmueble in the database
         const inm = await postInmueble(inmueble)
         // we create the form data in order to pass the files to our backend
         // every file has as a fieldname it's category
         const formData = new FormData()
         const keys = Object.keys(images)
         for (let i = 0; i < keys.length; i++) {
            for (let j = 0; j < images[keys[i]].length; j++) {
               formData.append(`${keys[i]}`, images[keys[i]][j])
            }
         }
         formData.append("mainImage", file)
         // we make the post request to the backend
         // this request will upload the images to the buckets
         // and will update our inmueble document to have the images urls
         const resPut = await putInmueble(formData, inm.id)
         // if everything is done correctly
         // we get redirected to the single inmueble page
         // of that specific inmueble and delete cache
         localStorage.removeItem("properties")
         navigate(`/inmuebles/${inm.id}`)
         setLoading(false)
      } catch (error) {
         setLoading(false)
         if (error.response.status === 500) {
            setError("Algo salio mal, intentalo mas tarde")
            return
         }
         setError(
            error.response?.data?.errors[0]?.msg
               ? error.response?.data.errors[0]?.msg
               : "Error al crear el inmueble",
         )
      }
   }

   return (
      <Page>
         <>
            {!showImageForm && (
               <ArrowBackIcon
                  className="font-bold mb-7 cursor-pointer mt-5"
                  onClick={() => navigate(-1)}
               />
            )}
            <div className="mb-5 flex flex-col max-w-[1600px] mx-auto px-4 md:px-[15%] gap-4">
               {!showImageForm ? (
                  <>
                     <h3 className="text-center text-2xl text-blue font-bold mt-5 mb-5">
                        Agregar Inmueble
                     </h3>
                     {error && (
                        <Alert
                           severity="error"
                           className="text-center text-2xl mb-5 w-[30%] mx-auto"
                        >
                           {error}
                        </Alert>
                     )}
                     <h3 className="text-blue mb-2">Información general</h3>
                     <DataRow>
                        <TextField
                           size="small"
                           label="Nombre de propiedad *"
                           fullWidth
                           value={name ? name : ""}
                           onChange={(e) => setName(e.target.value)}
                        />
                        <TextField
                           size="small"
                           label="URL CAD"
                           fullWidth
                           value={urlCAD ? urlCAD : ""}
                           onChange={(e) => setUrlCad(e.target.value)}
                        />
                     </DataRow>
                     <DataRow>
                        <BooleanToggle
                           label={
                              available === "true"
                                 ? "Disponible"
                                 : available === undefined
                                 ? "Sin actualizar"
                                 : "No disponible"
                           }
                           onPress={() => setAvailable(available === "true" ? "false" : "true")}
                           condition={available === "true"}
                        />
                        <BooleanToggle
                           label={
                              suspended === "true"
                                 ? "Suspendido"
                                 : suspended === undefined
                                 ? "Sin actualizar"
                                 : "Sin suspender"
                           }
                           onPress={
                              suspended === "true"
                                 ? () => setSuspended("false")
                                 : () => setSuspended("true")
                           }
                           condition={suspended === "false"}
                        />
                        <FormControl size="small">
                           <InputLabel>Estatus *</InputLabel>
                           <Select
                              displayEmpty
                              disabled={isLand}
                              value={status}
                              onChange={(e) => setStatus(e.target.value as Status)}
                              className="h-[38px]"
                              label="Estatus *"
                           >
                              {Object.values(Status).map((s, i) => (
                                 <MenuItem value={s} key={i}>
                                    {s}
                                 </MenuItem>
                              ))}
                           </Select>
                        </FormControl>
                     </DataRow>
                     <DataRow>
                        <FormControl size="small" fullWidth>
                           <InputLabel>Tipo de propiedad *</InputLabel>
                           <Select
                              displayEmpty
                              label="Tipo de propiedad *"
                              value={type}
                              onChange={(e) => handleTypeChange(e)}
                           >
                              {Object.values(PropertyType).map((t, i) => (
                                 <MenuItem value={t} key={i}>
                                    {t}
                                 </MenuItem>
                              ))}
                           </Select>
                        </FormControl>
                        <FormControl size="small" fullWidth>
                           <InputLabel>Condominio</InputLabel>
                           <Select
                              displayEmpty
                              label="Condominio"
                              value={condo ? condo : ""}
                              onChange={(e) => {
                                 setCondo(e.target.value)
                                 setZone("")
                              }}
                           >
                              {condominios?.map((cond) => (
                                 <MenuItem key={cond.value} value={cond.value}>
                                    {cond.label}
                                 </MenuItem>
                              ))}
                           </Select>
                        </FormControl>
                        <TextField
                           size="small"
                           label="Recámaras *"
                           disabled={isLand}
                           fullWidth
                           value={rooms ? rooms : ""}
                           onChange={(e) => setRooms(+e.target.value)}
                           inputProps={{inputMode: "numeric", pattern: "[0-9]*"}}
                        />
                        <TextField
                           size="small"
                           label="Baños *"
                           disabled={isLand}
                           fullWidth
                           value={bathrooms ? bathrooms : ""}
                           onChange={(e) => setBathrooms(+e.target.value)}
                           inputProps={{inputMode: "numeric", pattern: "[0-9]*"}}
                        />
                        <TextField
                           size="small"
                           label="Cajones de estacionamiento"
                           disabled={isLand}
                           fullWidth
                           value={parkingLots ? parkingLots : ""}
                           onChange={(e) => setParkingLots(+e.target.value)}
                           inputProps={{inputMode: "numeric", pattern: "[0-9]*"}}
                        />
                     </DataRow>
                     <DataRow>
                        <TextField
                           size="small"
                           label="Niveles"
                           disabled={isLand}
                           fullWidth
                           value={levels ? levels : ""}
                           onChange={(e) => setLevels(+e.target.value)}
                           inputProps={{inputMode: "numeric", pattern: "[0-9]*"}}
                        />
                        <>
                           {type === PropertyType.APARTMENT && (
                              <TextField
                                 size="small"
                                 label="Piso"
                                 fullWidth
                                 value={floor ? floor : ""}
                                 onChange={(e) => setFloor(+e.target.value)}
                                 inputProps={{inputMode: "numeric", pattern: "[0-9]*"}}
                              />
                           )}
                        </>
                        <TextField
                           size="small"
                           label="Superficie terreno Mts²"
                           fullWidth
                           value={mts ? mts : ""}
                           onChange={(e) => setMts(+e.target.value)}
                           inputProps={{inputMode: "numeric", pattern: "[0-9]*"}}
                           disabled={type === PropertyType.APARTMENT ? true : false}
                        />
                        <TextField
                           size="small"
                           label="Superficie construcción Mts²"
                           disabled={isLand}
                           fullWidth
                           value={mtsContruccion ? mtsContruccion : ""}
                           onChange={(e) => setMtsContruccion(+e.target.value)}
                           inputProps={{inputMode: "numeric", pattern: "[0-9]*"}}
                        />
                     </DataRow>
                     <DataRow>
                        <FormControl size="small" fullWidth>
                           <InputLabel>Zona</InputLabel>
                           <Select
                              label="Zona"
                              value={zone}
                              disabled={condo ? true : false}
                              onChange={(e) => setZone(e.target.value)}
                           >
                              {zones.map((z: string) => (
                                 <MenuItem value={z} key={z}>
                                    {z}
                                 </MenuItem>
                              ))}
                           </Select>
                        </FormControl>
                        <TextField
                           size="small"
                           label="Dirección"
                           fullWidth
                           value={address ? address : ""}
                           onChange={(e) => setAddress(e.target.value)}
                        />
                     </DataRow>
                     <DataRow>
                        <TextField
                           size="small"
                           label="Google Maps URL para EMBED"
                           fullWidth
                           value={mapsUrl}
                           onChange={(e) => setMapsUrl(e.target.value)}
                        />
                        <TextField
                           size="small"
                           label="Google Maps URL para Compartir"
                           fullWidth
                           value={mapsShareUrl}
                           onChange={(e) => setMapsShareUrl(e.target.value)}
                        />
                     </DataRow>
                     <FormControl size="small">
                        <InputLabel>Asociados</InputLabel>
                        <Select
                           multiple
                           value={partners}
                           onChange={handleChangePartners}
                           input={<OutlinedInput label="Asociados" />}
                           renderValue={(selected) => (
                              <Box sx={{display: "flex", flexWrap: "wrap", gap: 0.5}}>
                                 {selected.map((value) => {
                                    const partner: any = partnersFetched.find(
                                       (partner) => partner.value === value,
                                    )
                                    return (
                                       <Chip
                                          key={partner.value}
                                          label={partner.label}
                                          sx={{bgcolor: "#3f51b5", color: "white"}}
                                       />
                                    )
                                 })}
                              </Box>
                           )}
                        >
                           {partnersFetched.map((associate) => (
                              <MenuItem key={associate.value} value={associate.value}>
                                 {associate.label}
                              </MenuItem>
                           ))}
                        </Select>
                     </FormControl>
                     <h3>Ambientes y otros detalles</h3>
                     <DataRow>
                        {/* seaview */}
                        <FormControl size="small" fullWidth sx={{margin: "0px 2%"}}>
                           <InputLabel id="demo-controlled-open-select-label">
                              Vista al mar *
                           </InputLabel>
                           <Select
                              displayEmpty
                              labelId="demo-controlled-open-select-label"
                              id="demo-controlled-open-select"
                              label="Vista al mar *"
                              value={seaview}
                              onChange={(e) => setSeaview(e.target.value)}
                           >
                              <MenuItem value={"true"}>Si</MenuItem>
                              <MenuItem value={"false"}>No</MenuItem>
                           </Select>
                        </FormControl>
                        {/* furnished */}
                        <FormControl size="small" fullWidth sx={{margin: "0px 2%"}}>
                           <InputLabel id="demo-controlled-open-select-label">
                              Amueblado *
                           </InputLabel>
                           <Select
                              displayEmpty
                              labelId="demo-controlled-open-select-label"
                              id="demo-controlled-open-select"
                              label="Amueblado *"
                              disabled={isLand}
                              value={furnished}
                              onChange={(e) => {
                                 const value = e.target.value
                                 if (value === "true") {
                                    setFurnished(value)
                                    setFurnishType("Amueblado")
                                 } else {
                                    setFurnished(value)
                                    setFurnishType("Sin muebles")
                                 }
                              }}
                           >
                              <MenuItem value={"true"}>Si</MenuItem>
                              <MenuItem value={"false"}>No</MenuItem>
                           </Select>
                        </FormControl>
                        {/* type of furnish */}
                        <FormControl size="small" fullWidth sx={{margin: "0px 2%"}}>
                           <InputLabel id="demo-controlled-open-select-label">
                              Tipo de amueblado
                           </InputLabel>
                           <Select
                              displayEmpty
                              labelId="demo-controlled-open-select-label"
                              id="demo-controlled-open-select"
                              label="Tipo de amueblado"
                              disabled={isLand}
                              value={furnishType}
                              onChange={(e) => {
                                 const value = e.target.value
                                 if (value === "Amueblado" || value === "Mobiliario a tratar") {
                                    setFurnished("true")
                                    setFurnishType(value)
                                 } else {
                                    setFurnished("false")
                                    setFurnishType(value)
                                 }
                              }}
                           >
                              {furnishTypes.map((type) => (
                                 <MenuItem value={type} key={type}>
                                    {type}
                                 </MenuItem>
                              ))}
                           </Select>
                        </FormControl>
                     </DataRow>
                     {/* Ambientes */}
                     <FormControl size="small">
                        <InputLabel>Ambientes de la propiedad</InputLabel>
                        <Select
                           multiple
                           disabled={isLand}
                           value={environments}
                           onChange={handleChangeEnvironments}
                           label="Ambientes de la propiedad"
                           renderValue={(selected) => (
                              <Box sx={{display: "flex", flexWrap: "wrap", gap: 0.5}}>
                                 {selected.map((value) => (
                                    <Chip
                                       key={value}
                                       label={value}
                                       sx={{bgcolor: "#3f51b5", color: "white"}}
                                    />
                                 ))}
                              </Box>
                           )}
                        >
                           {type === PropertyType.HOUSE
                              ? allEnvs.map((env) => (
                                   <MenuItem key={env} value={env}>
                                      {env}
                                   </MenuItem>
                                ))
                              : envs.map((env) => (
                                   <MenuItem key={env} value={env}>
                                      {env}
                                   </MenuItem>
                                ))}
                        </Select>
                     </FormControl>
                     {type === PropertyType.HOUSE && (
                        <DataRow>
                           <FormControl size="small" fullWidth sx={{margin: "0px 2%"}}>
                              <InputLabel id="demo-controlled-open-select-label">
                                 Salida a la playa
                              </InputLabel>
                              <Select
                                 displayEmpty
                                 labelId="demo-controlled-open-select-label"
                                 id="demo-controlled-open-select"
                                 label="Salida a la playa"
                                 value={outingToTheBeach}
                                 onChange={(e) => setOutingToTheBeach(e.target.value)}
                              >
                                 <MenuItem value={"true"}>Si</MenuItem>
                                 <MenuItem value={"false"}>No</MenuItem>
                              </Select>
                           </FormControl>
                           <FormControl size="small" fullWidth sx={{margin: "0px 2%"}}>
                              <InputLabel id="demo-controlled-open-select-label">
                                 Antigüedad de la casa
                              </InputLabel>
                              <Select
                                 displayEmpty
                                 labelId="demo-controlled-open-select-label"
                                 id="demo-controlled-open-select"
                                 label="Antiguedad de la casa"
                                 value={houseAntiquity ? houseAntiquity : ""}
                                 onChange={(e) => setHouseAntiquity(e.target.value)}
                              >
                                 <MenuItem value={"En construcción"}>En construcción</MenuItem>
                                 <MenuItem value={"A estrenar"}>A estrenar</MenuItem>
                                 <MenuItem value={"Hasta 5 años"}>Hasta 5 años</MenuItem>
                                 <MenuItem value={"Hasta 10 años"}>Hasta 10 años</MenuItem>
                                 <MenuItem value={"Hasta 20 años"}>Hasta 20 años</MenuItem>
                                 <MenuItem value={"Hasta 50 años"}>Hasta 50 años</MenuItem>
                              </Select>
                           </FormControl>
                        </DataRow>
                     )}
                     <h3>Información de Venta</h3>
                     <DataRow>
                        {/* costoVenta */}
                        <TextField
                           size="small"
                           label="Precio de venta MXN"
                           id="outlined-size-small"
                           fullWidth
                           sx={{margin: "0px 2%"}}
                           value={costoVenta ? costoVenta : ""}
                           onChange={(e) => setCostoVenta(+e.target.value)}
                           inputProps={{inputMode: "numeric", pattern: "[0-9]*"}}
                        />
                        {/* pricePerMeter */}
                        <TextField
                           size="small"
                           label="Precio por metro cuadrado"
                           id="outlined-size-small"
                           fullWidth
                           sx={{margin: "0px 2%"}}
                           value={
                              costoVenta && mtsContruccion
                                 ? (costoVenta / mtsContruccion).toFixed(2)
                                 : ""
                           }
                           inputProps={{inputMode: "numeric", pattern: "[0-9]*"}}
                           disabled
                        />
                        {/* maintenance */}
                        <TextField
                           size="small"
                           label="Mantenimiento mensual MXN"
                           id="outlined-size-small"
                           fullWidth
                           sx={{margin: "0px 2%"}}
                           value={maintenance ? maintenance : ""}
                           onChange={(e) => setMaintenace(+e.target.value)}
                           inputProps={{inputMode: "numeric", pattern: "[0-9]*"}}
                        />
                     </DataRow>
                     <FormControl size="small">
                        <InputLabel>Comisión de Venta Pactada (%)</InputLabel>
                        <Select
                           displayEmpty
                           label="Comisión de Venta Pactada (%)"
                           value={saleComission}
                           onChange={(e) => setSaleComission(e.target.value as number)}
                        >
                           {Array.from({length: 19}, (_, i) => i * 0.5 + 1).map((i) => (
                              <MenuItem key={i} value={i}>
                                 {i}%
                              </MenuItem>
                           ))}
                        </Select>
                     </FormControl>
                     <h3>Información de Renta</h3>
                     <DataRow>
                        <TextField
                           size="small"
                           label="MXN Renta/noche temporada regular"
                           disabled={isLand}
                           fullWidth
                           value={noche_regular ? noche_regular : ""}
                           onChange={(e) => setNocheRegular(+e.target.value)}
                           inputProps={{inputMode: "numeric", pattern: "[0-9]*"}}
                        />
                        <TextField
                           size="small"
                           label="MXN Renta/noche temporada media"
                           disabled={isLand}
                           fullWidth
                           value={noche_media ? noche_media : ""}
                           onChange={(e) => setNocheMedia(+e.target.value)}
                           inputProps={{inputMode: "numeric", pattern: "[0-9]*"}}
                        />
                        <TextField
                           size="small"
                           label="MXN Renta/noche temporada alta"
                           disabled={isLand}
                           fullWidth
                           value={noche_alta ? noche_alta : ""}
                           onChange={(e) => setNocheAlta(+e.target.value)}
                           inputProps={{inputMode: "numeric", pattern: "[0-9]*"}}
                        />
                     </DataRow>
                     <DataRow>
                        <TextField
                           size="small"
                           label="MXN Renta/mes contrato regular"
                           id="outlined-size-small"
                           fullWidth
                           sx={{margin: "0px 2%"}}
                           value={mensual_regular ? mensual_regular : ""}
                           onChange={(e) => setMensualRegular(+e.target.value)}
                           inputProps={{inputMode: "numeric", pattern: "[0-9]*"}}
                        />
                        <TextField
                           size="small"
                           label="MXN Renta/mes contrato anual"
                           id="outlined-size-small"
                           fullWidth
                           sx={{margin: "0px 2%"}}
                           value={mensual_anual ? mensual_anual : ""}
                           onChange={(e) => setMensualAnual(+e.target.value)}
                           inputProps={{inputMode: "numeric", pattern: "[0-9]*"}}
                        />
                     </DataRow>
                     <DataRow>
                        <TextField
                           size="small"
                           label="Capacidad máxima"
                           fullWidth
                           disabled={isLand}
                           value={capacity ? capacity : ""}
                           onChange={(e) => setCapacity(+e.target.value)}
                           inputProps={{inputMode: "numeric", pattern: "[0-9]*"}}
                        />
                        {/* Pet Friendly */}
                        <FormControl size="small" sx={{margin: "0px 2%", width: "100%"}}>
                           <InputLabel id="demo-controlled-open-select-label">
                              Pet Friendly
                           </InputLabel>
                           <Select
                              displayEmpty
                              labelId="demo-controlled-open-select-label"
                              id="demo-controlled-open-select"
                              disabled={isLand}
                              label="Pet Friendly"
                              value={petFriendly}
                              onChange={(e) => setPetFriendly(e.target.value)}
                           >
                              <MenuItem value={"true"}>Sí</MenuItem>
                              <MenuItem value={"false"}>No</MenuItem>
                           </Select>
                        </FormControl>
                     </DataRow>
                     <h3 className="text-blue">Descripción de la propiedad</h3>
                     {/* description2 */}
                     <TextField
                        size="small"
                        label="Descripción breve (ficha técnica)"
                        id="outlined-size-small"
                        fullWidth
                        multiline
                        sx={{margin: "0px 2%"}}
                        value={desc2 ? desc2 : ""}
                        onChange={(e) => setDesc2(e.target.value)}
                     />
                     {/* description */}
                     <TextField
                        size="small"
                        label="Descripción detallada"
                        id="outlined-size-small"
                        fullWidth
                        multiline
                        sx={{margin: "0px 2%"}}
                        value={desc ? desc : ""}
                        onChange={(e) => setDesc(e.target.value)}
                     />
                     <h3 className="text-blue">Notas de la propiedad</h3>
                     <h3>Notas públicas</h3>
                     {/* public notes */}
                     <TextField
                        size="small"
                        label="Notas públicas"
                        id="outlined-size-small"
                        fullWidth
                        multiline
                        sx={{margin: "0px 2%"}}
                        value={notes ? notes : ""}
                        onChange={(e) => setNotes(e.target.value)}
                     />
                     <h3>Notas privadas</h3>
                     {/* private notes */}
                     <TextField
                        size="small"
                        label="Notas privadas 🔐"
                        id="outlined-size-small"
                        fullWidth
                        multiline
                        sx={{margin: "0px 2%"}}
                        value={privateNotes ? privateNotes : ""}
                        onChange={(e) => setPrivateNotes(e.target.value)}
                     />
                     <h3>Imágenes</h3>
                     <div className="w-full my-0 mx-[2%] flex-col space-x-2">
                        <Button
                           className="w-full my-auto mx-[2%] flex space-x-2"
                           variant="outlined"
                           startIcon={<AttachFileIcon />}
                           onClick={() => {
                              const fileInput: any = document.querySelector("#file_input")
                              fileInput?.click()
                           }}
                        >
                           {file ? file.name : "Adjuntar imagen principal"}
                        </Button>
                        <input
                           id="file_input"
                           required
                           type="file"
                           style={{display: "none"}}
                           accept=".jpg,.png,.jpeg"
                           onChange={(e) => imageHandler(e)}
                        />
                     </div>
                     {/* image categories */}
                     <div className="w-full my-0 mx-[2%] flex-col space-x-2 ">
                        <div className="w-full my-0 mx-[2%] flex space-x-2">
                           <TextField
                              size="small"
                              label="Categorías de imágenes"
                              id="outlined-size-small"
                              fullWidth
                              sx={{margin: "0px 2%"}}
                              value={categoria ? categoria : ""}
                              onChange={(e) => setCategoria(e.target.value)}
                           />
                           <AddIcon
                              className="text-blue-600 self-center"
                              onClick={handleCategoriasChange}
                           />
                        </div>
                        <ReactSortable
                           list={categories}
                           setList={setCategories}
                           className="flex flex-wrap flex-col"
                           delay={isMobile() ? 200 : 0}
                        >
                           {categories.length > 0 &&
                              categories.map((categoria, index) => (
                                 <div
                                    key={categoria.id}
                                    className="flex space-x-4 my-2 border rounded p-5"
                                 >
                                    <p className="w-[100%] overflow-x-scroll overflow-y-hidden">
                                       {categoria.name}
                                    </p>
                                    <EditIcon
                                       className="text-green self-center"
                                       onClick={() => handleImageEdit(index)}
                                    />
                                    <DeleteIcon
                                       className="text-red self-center"
                                       onClick={() => {
                                          setConfirmationOpen(true)
                                          setCategoryToDelete(index)
                                       }}
                                    />
                                 </div>
                              ))}
                        </ReactSortable>
                     </div>
                     <ConfirmationDialog
                        open={confirmationOpen}
                        onCancel={() => setConfirmationOpen(false)}
                        onConfirm={() => {
                           setConfirmationOpen(false)
                           handleCategoriaDelete(categoryToDelete)
                        }}
                        message="¿Eliminar categoría de imágenes?"
                     />
                     <h3>Videos</h3>
                     {/* video links */}
                     <div className="w-full my-0 mx-[2%] flex-col space-x-2 ">
                        <div className="w-full my-0 mx-[2%] flex space-x-2">
                           <TextField
                              size="small"
                              label="Links de videos"
                              id="outlined-size-small"
                              fullWidth
                              sx={{margin: "0px 2%"}}
                              value={videos ? videos : ""}
                              onChange={(e) => setVideos(e.target.value)}
                           />
                           <AddIcon
                              className="text-blue-600 self-center"
                              onClick={handleVideoChange}
                           />
                        </div>
                        {videosList.length > 0 &&
                           videosList.map((video, index) => (
                              <div key={video} className="flex space-x-4 my-4 border rounded p-5">
                                 <p className="w-[100%] overflow-x-scroll overflow-y-hidden">
                                    {video}
                                 </p>
                                 <DeleteIcon
                                    className="text-red-600 self-center"
                                    onClick={() => handleVideoDelete(index)}
                                 />
                              </div>
                           ))}
                     </div>
                     {/* add button */}
                     {!loading && (
                        <button
                           className="rounded bg-blue-600 p-2 mb-5 text-white font-bold w-[200px] self-center"
                           onClick={handleSubmit}
                        >
                           Agregar Inmueble
                        </button>
                     )}
                     {loading && (
                        <button
                           className="rounded bg-blue-600 p-2 mb-5 text-white font-bold w-[200px] self-center"
                           disabled
                        >
                           Cargando...
                        </button>
                     )}
                  </>
               ) : (
                  // if showImageForm is true, then this is displayed
                  <UploadImagesForm
                     addImages={addImages}
                     imageName={currImage}
                     images={images[currImage]}
                     closeImageForm={closeImageForm}
                  />
               )}
            </div>
         </>
      </Page>
   )
}

export default AddInmueble
