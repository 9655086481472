import React, {useState} from "react"
import {useNavigate} from "react-router-dom"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import AddIcon from "@mui/icons-material/Add"
import DeleteIcon from "@mui/icons-material/Delete"
import EditIcon from "@mui/icons-material/Edit"
import AttachFileIcon from "@mui/icons-material/AttachFile"
import DataRow from "@/components/ui/DataRow"
import Page from "@/layouts/Page"
import {Button} from "@mui/material"
import {Box, TextField} from "@mui/material"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Select, {SelectChangeEvent} from "@mui/material/Select"
import Chip from "@mui/material/Chip"
import OutlinedInput from "@mui/material/OutlinedInput"
import Alert from "@mui/material/Alert"
import UploadImagesForm from "@/components/ui/UploadImagesForm"
import {postCondominio, putCondominioImages} from "@/api/condominio"
import {useAuthContext} from "@/hooks/useAuthContext"
import {ReactSortable} from "react-sortablejs"
import {nanoid} from "nanoid"
import {accessToBeachValues, classifications, condoAmenities, zones} from "@/constants"
import ConfirmationDialog from "@/components/ui/ConfirmationDialog"
import {checkNameTaken} from "@/api/utils"

import {isMobile} from "@/utils"

interface SortableSectionsType {
   name: string
   id: string
}

function AddCondominio() {
   const navigate = useNavigate()
   const {user} = useAuthContext()
   const [error, setError] = useState<boolean | string>(false)
   const [name, setName] = useState("")
   const [desc, setDesc] = useState("")
   const [videoId, setVideoId] = useState("")
   const [address, setAddress] = useState("")
   const [googleMapsLink, setGoogleMapsLink] = useState("")
   const [mapsShareUrl, setMapsShareUrl] = useState("")
   const [zone, setZone] = useState("")
   const [accessToBeach, setAccessToBeach] = useState("")
   const [beachClub, setBeachClub] = useState("")
   const [petFriendly, setPetFriendly] = useState("")
   const [vactionRentalsAllowed, setVactionRentalsAllowed] = useState("")
   const [antiquity, setAntiquity] = useState("")
   const [amenities, setAmenities] = useState<string[]>([])
   const [condoSize, setCondoSize] = useState("")
   const [classification, setClassification] = useState("")
   const [mainImage, setMainImage] = useState<File | null>(null)
   const [showImageForm, setShowImageForm] = useState<boolean>(false)
   const [categoria, setCategoria] = useState<string>("")
   const [categories, setCategories] = useState<Array<SortableSectionsType>>([])
   const [currImage, setCurrImage] = useState<string>("")
   const [images, setImages] = useState<any>()
   const [loading, setLoading] = useState<boolean>(false)
   const [confirmationOpen, setConfirmationOpen] = useState<boolean>(false)
   const [categoryToDelete, setCategoryToDelete] = useState<number>(0)

   // in order to update the enviroment state, which is an string array
   const handleChangeAmenities = (event: SelectChangeEvent<typeof amenities>) => {
      const {
         target: {value},
      } = event
      setAmenities(
         // On autofill we get a stringified value.
         typeof value === "string" ? value.split(",") : value,
      )
   }

   const closeImageForm = () => {
      setShowImageForm(false)
   }

   // handle change for the main image
   const imageHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
      setMainImage(null)
      setError(false)
      if (!e.target.files) {
         setError("Selecciona un imagen")
         return
      }
      if (!e.target.files[0].type.includes("image")) {
         setError("Solo se aceptan imagenes")
         return
      }
      if (
         e.target.files[0].name.endsWith("jpg") ||
         e.target.files[0].name.endsWith("png") ||
         e.target.files[0].name.endsWith("jpeg") ||
         e.target.files[0].name.endsWith("JPEG") ||
         e.target.files[0].name.endsWith("JPG") ||
         e.target.files[0].name.endsWith("PNG")
      ) {
         setMainImage(e.target.files[0])
         setError(false)
      } else {
         setError("Solo se aceptan imagenes jpeg o png")
         return
      }
   }

   const handleCategoriasChange = () => {
      setError(false)
      // if no category was added then an error is set
      if (categoria === "") {
         setError("Ingresa una categoria")
         return
      }
      // if the category list already exists we just add the new category to it
      // and also to the images object, which will be empty until
      // it is change in the backend
      if (categories) {
         setCategories((prevCategories) => [
            ...prevCategories,
            {
               id: nanoid(),
               name: categoria.trim(),
            },
         ])
         setImages((prevImages: any) => {
            if (!prevImages) {
               return {
                  [categoria.trim()]: [],
               }
            }
            const keys = Object.keys(prevImages)
            keys.push(categoria.trim())
            const newImages: any = {}
            for (let i = 0; i < keys.length; i++) {
               newImages[keys[i]] = []
            }
            return newImages
         })
      }
      setCategoria("")
   }

   // everytime the user deletes a category, we remove it from the categories state
   const handleCategoriaDelete = (index: number) => {
      setImages((prevImages: any) => {
         delete prevImages[categories[index].name]
         return prevImages
      })
      const categoriesUpdated = categories.filter((video, i) => i !== index)
      setCategories(categoriesUpdated)
   }

   // when the user decides to edit a category and add images this function
   // is called, it sets the currImage state to the category that is being edited
   // and also sets the showImageForm state to true, which will show the image form
   const handleImageEdit = (index: number) => {
      setCurrImage(categories[index].name)
      setShowImageForm(true)
   }

   // when the user clicks the upload images button in the image form
   // this function is called, it adds the images to the images state
   // in its respective category and also sets the showImageForm state to false
   const addImages = (images: any, imageCategory: string) => {
      setImages((prevImages: any) => {
         prevImages[imageCategory] = [...images]
         return prevImages
      })
      setShowImageForm(false)
   }

   const handleSubmit = async () => {
      setError(false)
      setLoading(true)
      // required fields
      if (!name || name === "") {
         setError("Ingresa un nombre")
         setLoading(false)
         return
      }
      if (await checkNameTaken(name, "condominios")) {
         setError("El nombre del condominio ya está en uso")
         setLoading(false)
         return
      }
      if (!zone || zone === "") {
         setError("Ingresa una zona")
         setLoading(false)
         return
      }
      if (!classification || classification === "") {
         setError("Ingresa una clasificacion")
         setLoading(false)
         return
      }
      if (accessToBeach === "") {
         setError("Selecciona un acceso a la playa")
         setLoading(false)
         return
      }
      if (beachClub === "") {
         setError("Selecciona un beach club")
         setLoading(false)
         return
      }
      if (petFriendly === "") {
         setError("Selecciona si es pet friendly")
         setLoading(false)
         return
      }
      if (vactionRentalsAllowed === "") {
         setError("Selecciona si se permiten rentas vacacionales")
         setLoading(false)
         return
      }
      if (!images) {
         setLoading(false)
         setError("Agregue una o mas categorias de imagenes")
         return
      }
      if (condoSize === "") {
         setError("Selecciona el tamaño del condominio")
         setLoading(false)
         return
      }
      if (!mainImage) {
         setError("Selecciona una imagen principal")
         setLoading(false)
         return
      }
      if (!mapsShareUrl || mapsShareUrl === "") {
         setError("Ingresa un link de google maps para compartir")
         setLoading(false)
         return
      }
      const keys = Object.keys(images)
      let specificImageError = false
      for (let i = 0; i < keys.length; i++) {
         if (images[keys[i]].length <= 0) {
            setError(`Agregue una o mas imagenes a la categoria ${keys[i]}`)
            setLoading(false)
            specificImageError = true
            return
         }
      }
      if (specificImageError) {
         setLoading(false)
         return
      }
      // create the images object with the categorias fields
      // this fields are empty arrays at the moment
      const categoriesVacias: any = {}
      for (let i = 0; i < categories.length; i++) {
         categoriesVacias[categories[i].name] = []
      }
      // transform to booleans
      const beachClubBool = beachClub === "true" ? true : false
      const petFriendlyBool = petFriendly === "true" ? true : false
      const vactionRentalsAllowedBool = vactionRentalsAllowed === "true" ? true : false
      // create condo object
      const condo: any = {
         name: name,
         desc,
         videoId,
         address,
         googleMaps: googleMapsLink,
         zone,
         access_to_beach: accessToBeach,
         beach_club: beachClubBool,
         pet_friendly: petFriendlyBool,
         vacation_rentals_allowed: vactionRentalsAllowedBool,
         antiquity,
         amenities,
         condo_size: condoSize,
         images: categoriesVacias,
         orderedSections: categories.map((category) => category.name),
         class: classification,
         mapsShareUrl,
      }
      const condoFields = Object.keys(condo)
      for (const key in condoFields) {
         if (
            (condo[condoFields[key]] === null ||
               condo[condoFields[key]] === undefined ||
               condo[condoFields[key]] === "" ||
               condo[condoFields[key]].length === 0) &&
            condoFields[key] !== "mainImage"
         ) {
            delete condo[condoFields[key]]
         }
      }
      try {
         // we make the post request to the backend
         // this request will create the condominio in the database
         //  without the images
         const cond: any = await postCondominio(condo)
         // we create the form data in order to pass the files to our backend
         // every file has as a fieldname it's category
         const formData = new FormData()
         const keys = Object.keys(images)
         for (let i = 0; i < keys.length; i++) {
            for (let j = 0; j < images[keys[i]].length; j++) {
               formData.append(`${keys[i]}`, images[keys[i]][j])
            }
         }
         formData.append("mainImage", mainImage)
         // we make the post request to the backend
         // this request will upload the images to the buckets
         // and will update our condominio document to have the images urls
         await putCondominioImages(formData, cond.id)
         // if everything is done correctly
         // we get redirected to the single inmueble page
         // of that specific inmueble and delete cache
         localStorage.removeItem("condos")
         navigate(`/condominios/${cond.id}`)
         setLoading(false)
      } catch (error) {
         setLoading(false)
         if (error.response.status === 500) {
            setError("Algo salio mal, intentalo mas tarde")
            return
         }
         setError(
            error.response?.data?.errors[0]?.msg
               ? error.response.data.errors[0].msg
               : "Ocurrio un error al crear el condominio",
         )
      }
   }

   return (
      <Page>
         <>
            {/* if clicked you are returned to the latest page */}
            {!showImageForm && (
               <ArrowBackIcon
                  className="font-bold mb-7 cursor-pointer mt-5"
                  onClick={() => navigate(-1)}
               />
            )}
            {/* in this div all our inputs are shown */}
            <div className="mb-5 flex flex-col gap-2 max-w-[1600px] mx-auto px-4 md:px-[15%]">
               {/*
         if the showImageForm state is false, then the normal fields are displayed, 
         if not the image fields are displayed */}
               {!showImageForm ? (
                  <>
                     {/* title */}
                     <h3 className="text-center text-2xl text-blue font-bold my-5">
                        Agregar Condominio
                     </h3>
                     {/* error alert */}
                     {error && (
                        <Alert
                           severity="error"
                           className="text-center text-2xl mb-5 w-[30%] mx-auto"
                        >
                           {error}
                        </Alert>
                     )}
                     <h3 className="text-blue">Información general</h3>
                     <DataRow>
                        {/* name */}
                        <TextField
                           size="small"
                           label="Nombre del condominio *"
                           id="outlined-size-small"
                           fullWidth
                           sx={{margin: "0px 2%"}}
                           value={name ? name : ""}
                           onChange={(e) => setName(e.target.value)}
                        />
                        {/* clasificación */}
                        <FormControl size="small" fullWidth sx={{margin: "0px 2%"}}>
                           <InputLabel id="demo-controlled-open-select-label">
                              Clasificación *
                           </InputLabel>
                           <Select
                              displayEmpty
                              labelId="demo-controlled-open-select-label"
                              id="demo-controlled-open-select"
                              label="Clasificación *"
                              value={classification ? classification : ""}
                              onChange={(e) => setClassification(e.target.value)}
                           >
                              {classifications.map((c: string) => {
                                 return (
                                    <MenuItem value={c} key={c}>
                                       {c}
                                    </MenuItem>
                                 )
                              })}
                           </Select>
                        </FormControl>
                     </DataRow>
                     <DataRow>
                        {/* zona */}
                        <FormControl size="small" fullWidth sx={{margin: "0px 2%"}}>
                           <InputLabel id="demo-controlled-open-select-label">Zona *</InputLabel>
                           <Select
                              displayEmpty
                              labelId="demo-controlled-open-select-label"
                              id="demo-controlled-open-select"
                              label="Zona *"
                              value={zone}
                              onChange={(e) => setZone(e.target.value)}
                           >
                              {zones.map((z: string) => (
                                 <MenuItem value={z} key={z}>
                                    {z}
                                 </MenuItem>
                              ))}
                           </Select>
                        </FormControl>
                        {/* address */}
                        <TextField
                           size="small"
                           label="Dirección *"
                           id="outlined-size-small"
                           fullWidth
                           sx={{margin: "0px 2%"}}
                           value={address ? address : ""}
                           onChange={(e) => setAddress(e.target.value)}
                        />
                     </DataRow>
                     {/* googleMaps embed url */}
                     <TextField
                        size="small"
                        label="Google Maps URL para EMBED"
                        id="outlined-size-small"
                        fullWidth
                        sx={{margin: "0px 2%"}}
                        value={googleMapsLink ? googleMapsLink : ""}
                        onChange={(e) => setGoogleMapsLink(e.target.value)}
                     />
                     {/* googleMaps share url */}
                     <TextField
                        size="small"
                        label="Google Maps URL para compartir"
                        id="outlined-size-small"
                        fullWidth
                        sx={{margin: "0px 2%"}}
                        value={mapsShareUrl}
                        onChange={(e) => setMapsShareUrl(e.target.value)}
                     />
                     <DataRow>
                        {/* accessToTheBeach */}
                        <FormControl size="small" fullWidth sx={{margin: "0px 2%"}}>
                           <InputLabel id="demo-controlled-open-select-label">
                              Acceso a la playa *
                           </InputLabel>
                           <Select
                              displayEmpty
                              labelId="demo-controlled-open-select-label"
                              id="demo-controlled-open-select"
                              label="Acceso a la playa *"
                              value={accessToBeach}
                              onChange={(e) => {
                                 const value = e.target.value
                                 setAccessToBeach(value)
                                 if (value === accessToBeachValues[1]) {
                                    // si el valor es el de club de playa, setear club de playa a verdadero
                                    setBeachClub("true")
                                 }
                              }}
                           >
                              {accessToBeachValues.map((access: string) => {
                                 return (
                                    <MenuItem value={access} key={access}>
                                       {access}
                                    </MenuItem>
                                 )
                              })}
                           </Select>
                        </FormControl>
                        {/* club de playa */}
                        <FormControl size="small" fullWidth sx={{margin: "0px 2%"}}>
                           <InputLabel id="demo-controlled-open-select-label">
                              Club de playa *
                           </InputLabel>
                           <Select
                              displayEmpty
                              labelId="demo-controlled-open-select-label"
                              id="demo-controlled-open-select"
                              label="Club de Playa *"
                              value={beachClub}
                              onChange={(e) => setBeachClub(e.target.value)}
                           >
                              <MenuItem value={"true"}>Sí</MenuItem>
                              <MenuItem value={"false"}>No</MenuItem>
                           </Select>
                        </FormControl>
                        {/* vaction_rentals_allowed */}
                        <FormControl size="small" fullWidth sx={{margin: "0px 2%"}}>
                           <InputLabel id="demo-controlled-open-select-label">
                              Rentas vacacionales permitidas *
                           </InputLabel>
                           <Select
                              displayEmpty
                              labelId="demo-controlled-open-select-label"
                              id="demo-controlled-open-select"
                              label="Rentas vacacionales permitidas *"
                              value={vactionRentalsAllowed}
                              onChange={(e) => setVactionRentalsAllowed(e.target.value)}
                           >
                              <MenuItem value={"true"}>Si</MenuItem>
                              <MenuItem value={"false"}>No</MenuItem>
                           </Select>
                        </FormControl>
                     </DataRow>
                     <DataRow>
                        {/* pet_friendly */}
                        <FormControl size="small" fullWidth sx={{margin: "0px 2%"}}>
                           <InputLabel id="demo-controlled-open-select-label">
                              Pet friendly *
                           </InputLabel>
                           <Select
                              displayEmpty
                              labelId="demo-controlled-open-select-label"
                              id="demo-controlled-open-select"
                              label="Pet friendly *"
                              value={petFriendly}
                              onChange={(e) => setPetFriendly(e.target.value)}
                           >
                              <MenuItem value={"true"}>Si</MenuItem>
                              <MenuItem value={"false"}>No</MenuItem>
                           </Select>
                        </FormControl>
                        {/* condo antiquity */}
                        <FormControl size="small" fullWidth sx={{margin: "0px 2%"}}>
                           <InputLabel id="demo-controlled-open-select-label">
                              Antigüedad del condominio
                           </InputLabel>
                           <Select
                              displayEmpty
                              labelId="demo-controlled-open-select-label"
                              id="demo-controlled-open-select"
                              label="Antiguedad del condominio"
                              value={antiquity ? antiquity : ""}
                              onChange={(e) => setAntiquity(e.target.value)}
                           >
                              <MenuItem value={"En construcción"}>En construcción</MenuItem>
                              <MenuItem value={"A estrenar"}>A estrenar</MenuItem>
                              <MenuItem value={"Hasta 5 años"}>Hasta 5 años</MenuItem>
                              <MenuItem value={"Hasta 10 años"}>Hasta 10 años</MenuItem>
                              <MenuItem value={"Hasta 20 años"}>Hasta 20 años</MenuItem>
                              <MenuItem value={"Hasta 50 años"}>Hasta 50 años</MenuItem>
                           </Select>
                        </FormControl>
                        {/* condo_size */}
                        <FormControl size="small" fullWidth sx={{margin: "0px 2%"}}>
                           <InputLabel id="demo-controlled-open-select-label">
                              Tamaño del condominio *
                           </InputLabel>
                           <Select
                              displayEmpty
                              labelId="demo-controlled-open-select-label"
                              id="demo-controlled-open-select"
                              label="Tamaño del condominio *"
                              value={condoSize ? condoSize : ""}
                              onChange={(e) => setCondoSize(e.target.value)}
                           >
                              <MenuItem value={"Chico < 40 propiedades"}>
                                 Chico {"<"} 40 propiedades
                              </MenuItem>
                              <MenuItem value={"Mediano entre 40 y 80 propiedades"}>
                                 Mediano entre 40 y 80 propiedades
                              </MenuItem>
                              <MenuItem value={"Grande +100 propiedades"}>
                                 Grande +100 propiedades
                              </MenuItem>
                           </Select>
                        </FormControl>
                     </DataRow>
                     {/* amenities */}
                     <FormControl size="small" sx={{margin: "0px 2%", width: "100%"}}>
                        <InputLabel id="demo-multiple-chip-label">Amenidades</InputLabel>
                        <Select
                           labelId="demo-multiple-chip-label"
                           id="demo-multiple-chip"
                           multiple
                           value={amenities}
                           onChange={handleChangeAmenities}
                           input={<OutlinedInput id="select-multiple-chip" label="Amenidades" />}
                           renderValue={(selected) => (
                              <Box sx={{display: "flex", flexWrap: "wrap", gap: 0.5}}>
                                 {selected.map((value) => (
                                    <Chip key={value} label={value} />
                                 ))}
                              </Box>
                           )}
                        >
                           {condoAmenities.map((amenity: string) => (
                              <MenuItem key={amenity} value={amenity}>
                                 {amenity}
                              </MenuItem>
                           ))}
                        </Select>
                     </FormControl>
                     <h3 className="text-blue">Descripción del condominio</h3>
                     {/* description */}
                     <TextField
                        size="small"
                        label="Descripción detallada *"
                        id="outlined-size-small"
                        fullWidth
                        multiline
                        sx={{margin: "0px 2%"}}
                        value={desc ? desc : ""}
                        onChange={(e) => setDesc(e.target.value)}
                     />
                     <h3 className="text-blue">Imágenes</h3>
                     <div className="w-full my-0 mx-[2%] flex-col space-x-2">
                        <Button
                           className="w-full my-auto mx-[2%] flex space-x-2"
                           variant="outlined"
                           startIcon={<AttachFileIcon />}
                           onClick={() => {
                              const fileInput: any = document.querySelector("#file_input")
                              fileInput?.click()
                           }}
                        >
                           {mainImage ? mainImage.name : "Adjuntar imagen principal *"}
                        </Button>
                        <input
                           id="file_input"
                           required
                           type="file"
                           style={{display: "none"}}
                           accept=".jpg,.jpeg,.png"
                           onChange={(e) => imageHandler(e)}
                        />
                     </div>
                     {/* image categories */}
                     <div className="w-full my-0 mx-[2%] flex-col space-x-2 ">
                        <div className="w-full my-0 mx-[2%] flex space-x-2">
                           <TextField
                              size="small"
                              label="Categorías de imágenes *"
                              id="outlined-size-small"
                              fullWidth
                              sx={{margin: "0px 2%"}}
                              value={categoria ? categoria : ""}
                              onChange={(e) => setCategoria(e.target.value)}
                           />
                           <AddIcon
                              className="text-blue-600 self-center"
                              onClick={handleCategoriasChange}
                           />
                        </div>
                        <ReactSortable
                           list={categories}
                           setList={setCategories}
                           className="flex flex-wrap flex-col"
                           delay={isMobile() ? 200 : 0}
                        >
                           {categories.length > 0 &&
                              categories.map((categoria, index) => (
                                 <div
                                    key={categoria.id}
                                    className="flex space-x-4 my-2 border rounded p-5"
                                 >
                                    <p className="w-[100%] overflow-x-auto overflow-y-hidden">
                                       {categoria.name}
                                    </p>
                                    <EditIcon
                                       className="text-green-600 self-center"
                                       onClick={() => handleImageEdit(index)}
                                    />
                                    <DeleteIcon
                                       className="text-red-600 self-center"
                                       onClick={() => {
                                          setConfirmationOpen(true)
                                          setCategoryToDelete(index)
                                       }}
                                    />
                                 </div>
                              ))}
                        </ReactSortable>
                     </div>
                     <ConfirmationDialog
                        open={confirmationOpen}
                        onCancel={() => setConfirmationOpen(false)}
                        onConfirm={() => {
                           setConfirmationOpen(false)
                           handleCategoriaDelete(categoryToDelete)
                        }}
                        message="¿Eliminar categoría de imágenes?"
                     />
                     <h3 className="text-blue">Videos</h3>
                     {/* videoID */}
                     <TextField
                        size="small"
                        label="ID del video"
                        id="outlined-size-small"
                        fullWidth
                        sx={{margin: "0px 2%"}}
                        value={videoId ? videoId : ""}
                        onChange={(e) => setVideoId(e.target.value)}
                     />
                     {/* add button */}
                     {!loading && (
                        <button
                           className="rounded bg-blue p-2 mb-5 text-white font-bold w-[200px] self-center"
                           onClick={handleSubmit}
                        >
                           Agregar Condominio
                        </button>
                     )}
                     {loading && (
                        <button
                           className="rounded bg-blue p-2 mb-5 text-white font-bold w-[200px] self-center"
                           disabled
                        >
                           Cargando...
                        </button>
                     )}
                  </>
               ) : (
                  // if showImageForm is true, then this is displayed
                  <UploadImagesForm
                     addImages={addImages}
                     imageName={currImage}
                     images={images[currImage]}
                     closeImageForm={closeImageForm}
                  />
               )}
            </div>
         </>
      </Page>
   )
}

export default AddCondominio
