import React, {useState, useEffect} from "react"
import {useLocation, useNavigate} from "react-router-dom"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import DataRow from "@/components/ui/DataRow"
import Page from "@/layouts/Page"
import {Box, TextField} from "@mui/material"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Select, {SelectChangeEvent} from "@mui/material/Select"
import Chip from "@mui/material/Chip"
import OutlinedInput from "@mui/material/OutlinedInput"
import Alert from "@mui/material/Alert"
import {editCondominio} from "@/api/condominio"
import {accessToBeachValues, classifications, zones} from "@/constants"
import {checkNameTaken} from "@/api/utils"

function EditCondominio() {
   const navigate = useNavigate()
   const location = useLocation()
   const pathName = location.pathname.split("/editCondominio/")[1]
   // error state
   const [error, setError] = useState<boolean | string>(false)
   // state variables
   const [name, setName] = useState("")
   const [prevName, setPrevName] = useState("")
   const [desc, setDesc] = useState("")
   const [videoId, setVideoId] = useState("")
   const [address, setAddress] = useState("")
   const [googleMapsLink, setGoogleMapsLink] = useState("")
   const [mapsShareUrl, setMapsShareUrl] = useState("")
   const [zone, setZone] = useState("")
   const [classification, setClassification] = useState("")
   const [accessToBeach, setAccessToBeach] = useState("")
   const [beachClub, setBeachClub] = useState("")
   const [petFriendly, setPetFriendly] = useState("")
   const [vactionRentalsAllowed, setVactionRentalsAllowed] = useState("")
   const [antiquity, setAntiquity] = useState("")
   const [amenities, setAmenities] = useState<string[]>([])
   const [condoSize, setCondoSize] = useState("")
   const [mainImage, setMainImage] = useState("")
   const [images, setImages] = useState<any>()
   const [orderedSections, setOrderedSections] = useState<string[]>([])
   // loading state
   const [loading, setLoading] = useState<boolean>(false)

   useEffect(() => {
      setLoading(true)
      const state: any = location.state
      const condominio: any = state.condominio
      if (!condominio) {
         navigate("/condominios")
         return
      }
      setName(condominio.name ? condominio.name : "")
      setPrevName(condominio.name ? condominio.name : "")
      setDesc(condominio.desc ? condominio.desc : "")
      setVideoId(condominio.videoId ? condominio.videoId : "")
      setAddress(condominio.address ? condominio.address : "")
      setGoogleMapsLink(condominio.googleMaps ? condominio.googleMaps : "")
      setMapsShareUrl(condominio.mapsShareUrl || "")
      setZone(condominio.zone ? condominio.zone : "")
      setAccessToBeach(condominio.access_to_beach)
      setBeachClub(condominio.beach_club === true ? "true" : "false")
      setPetFriendly(condominio.pet_friendly === true ? "true" : "false")
      setVactionRentalsAllowed(condominio.vacation_rentals_allowed === true ? "true" : "false")
      setAntiquity(condominio.antiquity ? condominio.antiquity : "")
      setAmenities(condominio.amenities ? condominio.amenities : [])
      setCondoSize(condominio.condo_size ? condominio.condo_size : "")
      setMainImage(condominio.mainImage)
      setImages(condominio.images)
      setClassification(condominio.class ? condominio.class : "")
      setOrderedSections(condominio.orderedSections ? condominio.orderedSections : [])
      setLoading(false)
   }, [])

   // in order to update the enviroment state, which is an string array
   const handleChangeAmenities = (event: SelectChangeEvent<typeof amenities>) => {
      const {
         target: {value},
      } = event
      setAmenities(
         // On autofill we get a stringified value.
         typeof value === "string" ? value.split(",") : value,
      )
   }

   const handleSubmit = async () => {
      setError(false)
      setLoading(true)
      // required fields
      if (!name || name === "") {
         setError("Ingresa un nombre")
         setLoading(false)
         return
      }
      if (name !== prevName) {
         if (await checkNameTaken(name, "condominios")) {
            setError("El nombre del condominio ya existe")
            setLoading(false)
            return
         }
      }
      if (!zone || zone === "") {
         setError("Ingresa una zona")
         setLoading(false)
         return
      }
      if (!classification || classification === "") {
         setError("Ingresa una clasificación")
         setLoading(false)
         return
      }
      if (accessToBeach === "") {
         setError("Selecciona un acceso a la playa")
         setLoading(false)
         return
      }
      if (beachClub === "") {
         setError("Selecciona un beach club")
         setLoading(false)
         return
      }
      if (petFriendly === "") {
         setError("Selecciona si es pet friendly")
         setLoading(false)
         return
      }
      if (vactionRentalsAllowed === "") {
         setError("Selecciona si se permiten rentas vacacionales")
         setLoading(false)
         return
      }
      if (condoSize === "") {
         setError("Selecciona el tamaño del condominio")
         setLoading(false)
         return
      }
      if (!mapsShareUrl || mapsShareUrl === "") {
         setError("Ingresa un link de google maps para compartir")
         setLoading(false)
         return
      }
      // transform to booleans
      const beachClubBool = beachClub === "true" ? true : false
      const petFriendlyBool = petFriendly === "true" ? true : false
      const vactionRentalsAllowedBool = vactionRentalsAllowed === "true" ? true : false
      // create condo object
      const condo: any = {
         name: name,
         desc,
         videoId,
         address,
         googleMaps: googleMapsLink === "" ? null : googleMapsLink,
         zone,
         access_to_beach: accessToBeach,
         beach_club: beachClubBool,
         pet_friendly: petFriendlyBool,
         vacation_rentals_allowed: vactionRentalsAllowedBool,
         antiquity,
         amenities,
         condo_size: condoSize,
         mainImage,
         images,
         orderedSections,
         class: classification,
         mapsShareUrl,
      }

      const condoFields = Object.keys(condo)
      for (const key in condoFields) {
         if (
            (condo[condoFields[key]] === null ||
               condo[condoFields[key]] === undefined ||
               condo[condoFields[key]] === "" ||
               condo[condoFields[key]].length === 0) &&
            condoFields[key] !== "mainImage"
         ) {
            delete condo[condoFields[key]]
         }
      }

      try {
         // we make the post request to the backend
         // this request will create the condominio in the database
         //  without the images
         await editCondominio(condo, pathName)
         // if everything is done correctly
         // we get redirected to the single inmueble page
         // of that specific inmueble and delete cache
         localStorage.removeItem("condos")
         navigate(`/condominios/${pathName}`)
         setLoading(false)
      } catch (error) {
         setLoading(false)
         console.log(error)
         if (error.response.status === 500) {
            setError("Algo salio mal, intentalo mas tarde")
            return
         }
         setError(
            error.response?.data?.errors[0]?.msg
               ? error.response.data.errors[0].msg
               : "Ocurrio un error al actualizar el condominio",
         )
      }
   }

   return (
      <Page>
         {/* if clicked you are returned to the latest page */}
         <ArrowBackIcon
            className="font-bold mb-7 cursor-pointer mt-5"
            onClick={() => navigate(-1)}
         />
         {/* in this div all our inputs are shown */}
         <div className="rounded mb-5 flex flex-col px-4 md:px-[15%] max-w-[1600px] mx-auto gap-4">
            {/*
         if the showImageForm state is false, then the normal fields are displayed, 
         if not the image fields are displayed */}
            {/* title */}
            <h3 className="text-center text-2xl text-blue font-bold mt-5 mb-5">Editar: {name}</h3>
            {/* error alert */}
            {error && (
               <Alert severity="error" className="text-center text-2xl mb-5 w-[30%] mx-auto">
                  {error}
               </Alert>
            )}
            <h3 className="text-blue">Información general</h3>
            <DataRow>
               {/* name */}
               <TextField
                  size="small"
                  label="Nombre del condominio *"
                  id="outlined-size-small"
                  fullWidth
                  sx={{margin: "0px 2%"}}
                  value={name ? name : ""}
                  onChange={(e) => setName(e.target.value)}
               />
               {/* clasificación */}
               <FormControl size="small" fullWidth sx={{margin: "0px 2%"}}>
                  <InputLabel id="demo-controlled-open-select-label">Clasificación *</InputLabel>
                  <Select
                     displayEmpty
                     labelId="demo-controlled-open-select-label"
                     id="demo-controlled-open-select"
                     label="Clasificación *"
                     value={classification ? classification : ""}
                     onChange={(e) => setClassification(e.target.value)}
                  >
                     {classifications.map((c) => (
                        <MenuItem key={c} value={c}>
                           {c}
                        </MenuItem>
                     ))}
                  </Select>
               </FormControl>
            </DataRow>
            <DataRow>
               {/* zona */}
               <FormControl size="small" fullWidth sx={{margin: "0px 2%"}}>
                  <InputLabel id="demo-controlled-open-select-label">Zona</InputLabel>
                  <Select
                     displayEmpty
                     labelId="demo-controlled-open-select-label"
                     id="demo-controlled-open-select"
                     label="Zona"
                     value={zone}
                     onChange={(e) => setZone(e.target.value)}
                  >
                     {zones.map((z) => (
                        <MenuItem key={z} value={z}>
                           {z}
                        </MenuItem>
                     ))}
                  </Select>
               </FormControl>
               {/* address */}
               <TextField
                  size="small"
                  label="Dirección"
                  id="outlined-size-small"
                  fullWidth
                  sx={{margin: "0px 2%"}}
                  value={address ? address : ""}
                  onChange={(e) => setAddress(e.target.value)}
               />
            </DataRow>
            {/* googleMaps embed url */}
            <TextField
               size="small"
               label="Google Maps URL para EMBED"
               id="outlined-size-small"
               fullWidth
               sx={{margin: "0px 2%"}}
               value={googleMapsLink ? googleMapsLink : ""}
               onChange={(e) => setGoogleMapsLink(e.target.value)}
            />
            {/* googleMaps share url */}
            <TextField
               size="small"
               label="Google Maps URL para compartir"
               id="outlined-size-small"
               fullWidth
               sx={{margin: "0px 2%"}}
               value={mapsShareUrl}
               onChange={(e) => setMapsShareUrl(e.target.value)}
            />
            <DataRow>
               {/* accessToTheBeach */}
               <FormControl size="small" fullWidth sx={{margin: "0px 2%"}}>
                  <InputLabel id="demo-controlled-open-select-label">Acceso a la playa</InputLabel>
                  <Select
                     displayEmpty
                     labelId="demo-controlled-open-select-label"
                     id="demo-controlled-open-select"
                     label="Acceso a la playa"
                     value={accessToBeach}
                     onChange={(e) => {
                        const value = e.target.value
                        setAccessToBeach(value)
                        if (value === accessToBeachValues[1]) {
                           // si el valor es el de club de playa, setear club de playa a verdadero
                           setBeachClub("true")
                        }
                     }}
                  >
                     {accessToBeachValues.map((a) => (
                        <MenuItem key={a} value={a}>
                           {a}
                        </MenuItem>
                     ))}
                  </Select>
               </FormControl>
               {/* club de playa */}
               <FormControl size="small" fullWidth sx={{margin: "0px 2%"}}>
                  <InputLabel id="demo-controlled-open-select-label">Club de playa</InputLabel>
                  <Select
                     displayEmpty
                     labelId="demo-controlled-open-select-label"
                     id="demo-controlled-open-select"
                     label="Club de Playa"
                     value={beachClub}
                     onChange={(e) => setBeachClub(e.target.value)}
                  >
                     <MenuItem value={"true"}>Si</MenuItem>
                     <MenuItem value={"false"}>No</MenuItem>
                  </Select>
               </FormControl>
               {/* vaction_rentals_allowed */}
               <FormControl size="small" fullWidth sx={{margin: "0px 2%"}}>
                  <InputLabel id="demo-controlled-open-select-label">
                     Rentas vacacionales permitidas
                  </InputLabel>
                  <Select
                     displayEmpty
                     labelId="demo-controlled-open-select-label"
                     id="demo-controlled-open-select"
                     label="Rentas vacacionales permitidas"
                     value={vactionRentalsAllowed}
                     onChange={(e) => setVactionRentalsAllowed(e.target.value)}
                  >
                     <MenuItem value={"true"}>Si</MenuItem>
                     <MenuItem value={"false"}>No</MenuItem>
                  </Select>
               </FormControl>
            </DataRow>
            <DataRow>
               {/* pet_friendly */}
               <FormControl size="small" fullWidth sx={{margin: "0px 2%"}}>
                  <InputLabel id="demo-controlled-open-select-label">Pet friendly</InputLabel>
                  <Select
                     displayEmpty
                     labelId="demo-controlled-open-select-label"
                     id="demo-controlled-open-select"
                     label="Pet friendly"
                     value={petFriendly}
                     onChange={(e) => setPetFriendly(e.target.value)}
                  >
                     <MenuItem value={"true"}>Si</MenuItem>
                     <MenuItem value={"false"}>No</MenuItem>
                  </Select>
               </FormControl>
               {/* condo antiquity */}
               <FormControl size="small" fullWidth sx={{margin: "0px 2%"}}>
                  <InputLabel id="demo-controlled-open-select-label">
                     Antigüedad del condominio
                  </InputLabel>
                  <Select
                     displayEmpty
                     labelId="demo-controlled-open-select-label"
                     id="demo-controlled-open-select"
                     label="Antiguedad del condominio"
                     value={antiquity ? antiquity : ""}
                     onChange={(e) => setAntiquity(e.target.value)}
                  >
                     <MenuItem value={"En construcción"}>En construcción</MenuItem>
                     <MenuItem value={"A estrenar"}>A estrenar</MenuItem>
                     <MenuItem value={"Hasta 5 años"}>Hasta 5 años</MenuItem>
                     <MenuItem value={"Hasta 10 años"}>Hasta 10 años</MenuItem>
                     <MenuItem value={"Hasta 20 años"}>Hasta 20 años</MenuItem>
                     <MenuItem value={"Hasta 50 años"}>Hasta 50 años</MenuItem>
                  </Select>
               </FormControl>
               {/* condo_size */}
               <FormControl size="small" fullWidth sx={{margin: "0px 2%"}}>
                  <InputLabel id="demo-controlled-open-select-label">
                     Tamaño del condominio
                  </InputLabel>
                  <Select
                     displayEmpty
                     labelId="demo-controlled-open-select-label"
                     id="demo-controlled-open-select"
                     label="Tamaño del condominio"
                     value={condoSize ? condoSize : ""}
                     onChange={(e) => setCondoSize(e.target.value)}
                  >
                     <MenuItem value={"Chico < 40 propiedades"}>
                        Chico {"<"} 40 propiedades
                     </MenuItem>
                     <MenuItem value={"Mediano entre 40 y 80 propiedades"}>
                        Mediano entre 40 y 80 propiedades
                     </MenuItem>
                     <MenuItem value={"Grande +100 propiedades"}>Grande +100 propiedades</MenuItem>
                  </Select>
               </FormControl>
            </DataRow>
            {/* amenities */}
            <FormControl size="small" sx={{margin: "0px 2%", width: "100%"}}>
               <InputLabel id="demo-multiple-chip-label">Amenidades</InputLabel>
               <Select
                  labelId="demo-multiple-chip-label"
                  id="demo-multiple-chip"
                  multiple
                  value={amenities}
                  onChange={handleChangeAmenities}
                  input={<OutlinedInput id="select-multiple-chip" label="Amenidades" />}
                  renderValue={(selected) => (
                     <Box sx={{display: "flex", flexWrap: "wrap", gap: 0.5}}>
                        {selected.map((value) => (
                           <Chip key={value} label={value} />
                        ))}
                     </Box>
                  )}
               >
                  <MenuItem value={"Albercas"}>Albercas</MenuItem>
                  <MenuItem value={"Cancha de Tenis"}>Cancha de Tenis</MenuItem>
                  <MenuItem value={"Cancha de Pádel"}>Cancha de Pádel</MenuItem>
                  <MenuItem value={"Gimnasio"}>Gimnasio</MenuItem>
                  <MenuItem value={"Salón multi usos"}>Salón multi usos</MenuItem>
                  <MenuItem value={"Ludoteca"}>Ludoteca</MenuItem>
                  <MenuItem value={"Spa"}>Spa</MenuItem>
                  <MenuItem value={"Estética"}>Estética</MenuItem>
                  <MenuItem value={"Restaurante"}>Restaurante</MenuItem>
                  <MenuItem value={"Cine"}>Cine</MenuItem>
                  <MenuItem value={"Salón de Jóvenes"}>Salón de Jóvenes</MenuItem>
                  <MenuItem value={"Snack Bar"}>Snack Bar</MenuItem>
                  <MenuItem value={"Elevador"}>Elevador</MenuItem>
                  <MenuItem value={"Seguridad 24/7"}>Seguridad 24/7</MenuItem>
                  <MenuItem value={"Acceso a personas con movilidad reducida"}>
                     Acceso a personas con movilidad reducida
                  </MenuItem>
                  <MenuItem value={"Estacionamiento de visitas"}>
                     Estacionamiento de visitas
                  </MenuItem>
                  <MenuItem value={"Roof Garden"}>Roof Garden</MenuItem>
                  <MenuItem value={"Clínica"}>Clínica</MenuItem>
               </Select>
            </FormControl>
            <h3 className="text-blue">Descripción del condominio</h3>
            {/* description */}
            <TextField
               size="small"
               label="Descripción detallada *"
               id="outlined-size-small"
               fullWidth
               multiline
               sx={{margin: "0px 2%"}}
               value={desc ? desc : ""}
               onChange={(e) => setDesc(e.target.value)}
            />
            <h3 className="text-blue">Videos</h3>
            {/* videoID */}
            <TextField
               size="small"
               label="ID del video"
               id="outlined-size-small"
               fullWidth
               sx={{margin: "0px 2%"}}
               value={videoId ? videoId : ""}
               onChange={(e) => setVideoId(e.target.value)}
            />
            {/* add button */}
            {!loading && (
               <button
                  className="rounded bg-blue-600 p-2 mb-5 text-white font-bold w-[200px] self-center"
                  onClick={handleSubmit}
               >
                  Guardar Cambios
               </button>
            )}
            {loading && (
               <button
                  className="rounded bg-blue-600 p-2 mb-5 text-white font-bold w-[200px] self-center"
                  disabled
               >
                  Cargando...
               </button>
            )}
         </div>
      </Page>
   )
}

export default EditCondominio
