import React from "react"
import {useNavigate} from "react-router-dom"
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined"
import ShowerOutlinedIcon from "@mui/icons-material/ShowerOutlined"
import BedOutlinedIcon from "@mui/icons-material/BedOutlined"
import ApartmentIcon from "@mui/icons-material/Apartment"
import TimeToLeaveIcon from "@mui/icons-material/TimeToLeave"
import SquareFoot from "@mui/icons-material/SquareFoot"
import GroupIcon from "@mui/icons-material/Group"
import StatusTile from "./StatusTile"
import {Property, PropertyType} from "@/types/Property"
import ShareBtn from "./ShareBtn"
import {useAuthContext} from "@/hooks/useAuthContext"

interface Props {
   inmueble: Property
}

function InmuebleCard({inmueble}: Props) {
   const navigate = useNavigate()
   const {user} = useAuthContext()

   const toProperty = () => {
      // navigate to another screen passing the inmueble and img condominio
      navigate(`/inmuebles/${inmueble.id}`, {state: {inmueble}})
   }

   const SchemeElement = (scheme = "sale") => (
      <p className="px-4 border border-blue rounded-md text-blue shadow-md">
         {scheme === "sale" ? "Venta" : "Renta"}
      </p>
   )

   const IconInfo = ({
      icon,
      text,
      mts = false,
   }: {
      icon: React.ReactNode
      text: string | number
      mts?: boolean
   }) => (
      <li className="self-center flex items-center space-x-1">
         <span className="text-green">{icon}</span>
         <p>
            {text || "-"}
            {mts && <sup>2</sup>}
         </p>
      </li>
   )

   return (
      <div
         className="flex flex-col gap-2 h-[690px] shadow-md border rounded-lg items-center hover:shadow-lg w-[320px] p-2 relative cursor-pointer"
         onClick={toProperty}
      >
         <img src={inmueble.mainImageUrl} className="rounded-lg w-full object-cover h-[250px]" />
         <ShareBtn
            className="absolute top-6 right-6 h-[40px] w-[40px] flex items-center justify-center"
            name={inmueble.name}
            condoId={inmueble.condo}
            costs={inmueble.costos}
            slug={inmueble.slug}
            description={inmueble.desc2}
            type={inmueble.type}
            explicitRent={false}
            zone={inmueble.zone}
            capacity={inmueble.capacity}
            surface={inmueble.mts}
            constructionMts={inmueble.mtsConstruction}
            mapsShareUrl={inmueble.mapsShareUrl}
            videos={inmueble.videos}
            accessToBeach={inmueble.accessToBeach}
         />
         <section className="flex gap-4">
            {inmueble.forSale && SchemeElement()}
            {inmueble.forRent && SchemeElement("rent")}
         </section>
         <StatusTile status={inmueble.status} className="self-end" />
         <header className="text-start w-full">
            <h3 className="text-lg font-bold">{inmueble.name}</h3>
            <p className="text-sm truncate">
               {inmueble.condoName ? inmueble.condoName : "Sin condominio"}
               {inmueble.condoClass ? ` - ${inmueble.condoClass}` : ""}
            </p>
            <p className="text-sm">{inmueble.type}</p>
            <p className="text-sm">{inmueble.accessToBeach}</p>
            {user.admin && (
               <p className="text-sm text-green font-bold truncate w-[100%] text-start">
                  {inmueble.partnerNames
                     ? `Responsable: ${inmueble.partnerNames.map((name) => name)}`
                     : "Sin responsable"}
               </p>
            )}
         </header>
         <ul className="flex flex-wrap text-sm space-x-2 text-start w-full">
            <IconInfo icon={<SquareFoot />} text={inmueble.mts ? inmueble.mts + " mts" : "-"} mts />
            <IconInfo
               icon={
                  inmueble.type === PropertyType.HOUSE ? <HomeOutlinedIcon /> : <ApartmentIcon />
               }
               text={inmueble.mtsConstruction ? inmueble.mtsConstruction + " mts" : "-"}
               mts
            />
            <IconInfo icon={<ShowerOutlinedIcon />} text={inmueble.bathrooms} />
            <IconInfo icon={<BedOutlinedIcon />} text={inmueble.rooms} />
            <IconInfo icon={<TimeToLeaveIcon />} text={inmueble.parkingLots} />
            <IconInfo icon={<GroupIcon />} text={inmueble.capacity} />
         </ul>
         <section className="flex flex-col text-start gap-0 items-start text-sm w-full">
            <p>{`Precio de venta: ${
               inmueble.costoVenta ? inmueble.costoVenta.toLocaleString() + " MXN" : "-"
            }`}</p>
            <p>
               {`
                  Renta/mes contrato regular: ${
                     inmueble.costos?.mensual_regular
                        ? inmueble.costos.mensual_regular.toLocaleString() + " MXN"
                        : "-"
                  }`}
            </p>
            <p>
               {`Renta/mes contrato anual: ${
                  inmueble.costos?.mensual_anual
                     ? inmueble.costos.mensual_anual.toLocaleString() + " MXN"
                     : "-"
               }`}
            </p>
            <p>
               {`
                  Renta/noche temporada alta:
                  ${
                     inmueble.costos?.noche_alta
                        ? inmueble.costos.noche_alta.toLocaleString() + " MXN"
                        : "-"
                  }`}
            </p>
            <p>
               {`
                  Renta/noche temporada media:
                  ${
                     inmueble.costos?.noche_media
                        ? inmueble.costos.noche_media.toLocaleString() + " MXN"
                        : "-"
                  }`}
            </p>
            <p>
               {`
                  Renta/noche temporada baja:
                  ${
                     inmueble.costos?.noche_regular
                        ? inmueble.costos.noche_regular.toLocaleString() + " MXN"
                        : "-"
                  }`}
            </p>
         </section>
         {/* tags for available, suspended and updated */}
         <section className="flex items-center justify-center gap-2">
            <div
               className={`border rounded inline-block ${
                  inmueble.available ? "bg-green" : "bg-red"
               } text-white font-bold text-sm  p-1 text-center`}
            >
               {inmueble.available ? "Disponible" : "No disponible"}
            </div>
            <div
               className={`border rounded inline-block ${
                  inmueble.suspended ? "bg-red" : "bg-green"
               } text-white font-bold text-sm  p-1 text-center`}
            >
               {inmueble.suspended ? "Suspendido" : "Sin suspender"}
            </div>
         </section>
      </div>
   )
}

export default InmuebleCard
